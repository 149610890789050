// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//server client environment
export const environment = {
  production: true,
  // baseUrl: 'http://localhost:5000/',
  // baseUrl: "http://localhost:80/", //the local hosted version

  // redirectUrl: "http://localhost:4200/",
  // redirectUrl: "http://localhost:4201/",////local iis

  // baseUrl: "https://staging.api.dash.egroup.com.au/", //staging backend
  // redirectUrl: "https://staging.dash.egroup.com.au/", //staging Frontend
  // baseUrl:'https://api.dash.egroup.cc/',
  // redirectUrl: 'https://dash.egroup.cc/',

  baseUrl: "https://api.dash.egroup.com.au/",
  redirectUrl: "https://dash.egroup.com.au/",
  scopeUri: ["api://42327a6d-7b61-40d2-a58d-f6aa5046a552/Egroupdashboard"],
  tenantId: "41c2c4f3-8835-419e-85e5-538fc38d8c07",
  uiClienId: "6165d28c-aff8-4045-94de-358a1668781f",

  //Azure Blob Storage/////
  accountName: "egroupstorage",
  containerName: "egdocument",
  key: "8FuRmmcv/hT2YOhu0Q0EthHRNNpIlOSudJiwENKSV5QmKyDwBh/0DTcR8qjCzkcB2bRrEN1QYvegKoCrLHDe/w==",

  //Dashboard PowerBI Charts
  kpichart1: "d5895553-abdf-455b-b27d-846c16cc292f",
  kpichart2: "6c35aca1-6cd9-4599-8fad-52b26c585169",
  incidentchart1: "a567f475-b2f2-442f-92f7-f6b4a7815bdc",
  incidentchart2: "1a8b30fa-f688-4f02-8792-e3affac715f8",
  logbookchart1: "6f9889c1-f5cd-46fe-b866-c2bce8455212",
  scanchart1: "44a6934e-04a4-4176-957f-7d9cfacc5bed", // 3 days
  scanchart2: "0945f760-f3eb-4b0a-8345-7618c1519f54", // bar chart
  scanchart3: "86589fc4-2a62-42e4-ba89-134e56275365", // pai chart
  qrpetroltablechart1: "2a0c6ff8-66d9-4cee-9462-3ce323c8dcd6",
  qrpetrolbarchart2: "3673336d-941d-4234-ae36-4243a3106a4d",
  qrpetrolpaichart3: "7286eaad-978f-407d-bacf-21e2117985b8",
};
